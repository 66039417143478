export default {
    id: '/WhatsAppConfig',
    type: 'object',
    properties: {
        chatbotId: { type: 'string' },
        whatsAppTemplateName: { type: 'string' },
        whatsAppTemplateParams: { type: 'object' },
        whatsAppTemplateAdditionalData: { type: 'object' },
        whatsAppTemplateButtons: { type: 'array' },
        flowId: { type: 'string' },
        defaultCountryPhoneNumberCode: { type: 'string' },
        defaultText: {
            title: { type: 'string' },
            description: { type: 'string' },
            checkboxMessage: { type: 'string' },
            buttonMessage: { type: 'string' },
        },
        successText: {
            title: { type: 'string' },
            paragraph: { type: 'string' },
        },
        alreadyAppliedText: {
            title: { type: 'string' },
            paragraph: { type: 'string' },
        },
        errorText: {
            title: { type: 'string' },
            paragraph: { type: 'string' },
        },
    },
    required: ['chatbotId', 'whatsAppTemplateName', 'flowId'],
}
