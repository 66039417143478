import styled from 'styled-components';


export const CenterContainer = styled.div`
    height: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img { 
        margin-bottom: 12px;
        height: 48px !important;
    }

`;

export const ContainerCenter = styled.div`
    height: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    img { 
        margin-bottom: 12px !important;
        height: 48px !important;
    }
    
    @media (max-width: 600px) {
      width: 100% !important;
      height: auto !important;
    }
`;

export const ContainerTitle = styled.h1`
    font-weight: bold;
    font-size: 35px !important;
    
    @media (max-width: 600px) {
      font-size: 24px !important;
    }
`;

export const ContainerParagraph = styled.p`
    margin-bottom: 25px;
    
    @media (max-width: 600px) {
      font-size: 16px !important;
    }
`;
