import styled from 'styled-components';

export const ContainerMain = styled.div`
    margin: 0px 20px 20px 20px;
    padding: 0;
`;

export const ContainerRow = styled.div`
    margin: 0px 20px 20px 0;
    padding: 0;
`;

export const ContainerTitle = styled.h1`
    font-weight: bold;
    font-size: 35px !important;
    
    @media (max-width: 600px) {
      font-size: 24px !important;
    }
`;

export const ContainerParagraph = styled.p`
    margin-bottom: 25px;
    
    @media (max-width: 600px) {
      font-size: 16px !important;
    }
`;

export const Agreement = styled.span`
  cursor: pointer;
  user-select: none;
  @media (max-width: 600px) {
      font-size: 16px;
    }
`;

export const AgreementLink = styled.span`
  margin-left: 22px;
  margin-top: 4px;
  @media (max-width: 600px) {
      font-size: 16px;
    }
`;

export const ButtonConfirm = styled.button`
    width: 100%;
    height: 35px;
    background-color: #0275d8;
    color: white;
    border: none;
    padding: 5px;
    line-height: normal !important;
    border-radius: 5px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    :hover {
        background-color: #026dca
    }
    
    @media (max-width: 600px) {
      font-size: 16px !important;
      height: 45px !important;
    }
`;

export const ContainerCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
`
