import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';

window.whatsAppOptInConfig = {
    defaultCountryPhoneNumberCode: window?.waConfig?.defaultCountryPhoneNumberCode || 'nl',
    autoOpen: window?.waConfig?.autoOpen || false,
    defaultText: {
        title: window?.waConfig?.defaultText?.title || 'Solliciteer via Whatsapp!',
        description: window?.waConfig?.defaultText?.description || 'Je kunt ook solliciteren via Whatsapp: snel en makkelijk. Vul je telefoonnummer in en je ontvangt een Whatsapp bericht.',
        checkboxMessage: window?.waConfig?.defaultText?.checkboxMessage || 'Ja, ik ga akkoord dat ik Whatsapp berichten ontvang voor deze sollicitatie.',
        checkboxUrlText: window?.waConfig?.defaultText?.checkboxUrlText || '',
        checkboxUrlHref: window?.waConfig?.defaultText?.checkboxUrlHref || '',
        buttonMessage: window?.waConfig?.defaultText?.buttonMessage || 'Solliciteer',
    },
    successText: {
        title: window?.waConfig?.successText?.title || 'Dank je!',
        paragraph: window?.waConfig?.successText?.paragraph || 'Je nummer is ontvangen. We sturen je zo een bericht op het door jou ingevoerde nummer.',
    },
    alreadyAppliedText: {
        title: window?.waConfig?.alreadyAppliedText?.title || 'Je hebt al gesolliciteerd... 😉',
        paragraph: window?.waConfig?.alreadyAppliedText?.paragraph || 'Je kunt helaas maar 1 keer solliciteren. Probeer het anders via het standaard formulier nog een keer.',
    },
    errorText: {
        title: window?.waConfig?.errorText?.title || 'Ai, foutmelding..',
        paragraph: window?.waConfig?.errorText?.paragraph || 'We kunnen de sollicitatie niet doorsturen.',
    }
}


ReactDOM.render(<App />, document.getElementById('whatsapp_opt_in'));
serviceWorker.unregister();


// Hot Module Replacement
if (module.hot) {
    module.hot.accept();
}