import Modal from 'react-modal'
import styled from 'styled-components'

export const ModalWindow = styled(Modal)`
    width: 500px !important;
    height: auto !important;
    padding: 20px;
    background: #e3f7d4;
    border-radius: 15px;
    position: absolute;
    overflow-y: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    max-height: 85vh;
    @media (max-width: 600px) {
      width: 75vw !important;
    }
`;

export const ButtonClose = styled.button`
    height: 15px !important;
    background-color: transparent;
    font-weight: bolder;
    border: none;
    cursor: pointer;
    float: right;
    user-select: none;
    width: 15px !important;
    padding: 0 !important;
`;


