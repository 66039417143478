import styled from 'styled-components';


export const ContainerTitle = styled.h1`
    font-weight: bold;
    font-size: 35px !important;
    
    @media (max-width: 600px) {
      font-size: 24px !important;
    }
`;

export const ContainerParagraph = styled.p`
    margin-bottom: 25px;
    
    @media (max-width: 600px) {
      font-size: 16px !important;
    }
`;
