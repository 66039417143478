import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
// import Modal from 'antd/lib/modal'
// import 'antd/lib/modal/style/index.css' //added specific style
import { Validator } from 'jsonschema'
import WhatsAppForm from '../WhatsAppForm'
import ConfigError from '../ConfigError';
import waConfigSchema from '../../jsonschemas/waConfig'
import {ButtonClose, ModalWindow} from './styles'


var validator = new Validator();

function WhatsAppModal({ visible, hideModal }) {
    const [validated, setValidated] = useState(false);
    const [validationErrors, setValidationErrors] = useState('');

    useEffect(() => {
        validator.addSchema(waConfigSchema, '/WhatsAppConfig');
        const { valid, errors } = validator.validate(window.waConfig, waConfigSchema);
        if (!valid) {
            setValidated(false);
            setValidationErrors(errors);
        } else {
            setValidated(true);
        }
    }, [visible]);

    return (
        <ModalWindow
            isOpen={visible}
            style={{
                overlay: {backgroundColor: 'rgba(0, 0, 0, 0.6)', zIndex: '10000'},
                content: {zIndex: '10000', outline: 'none'}
            }}
            onRequestClose={ e => {hideModal();} }
            closeTimeoutMS={500}
            id="modal-window"
        >

            <ButtonClose
                className="modal-close"
                onClick={e => {hideModal();}}>
                X
            </ButtonClose>

            {validated ? <WhatsAppForm visible={visible} /> : <ConfigError validationErrors={validationErrors} />}

        </ModalWindow>
    )
}

export default WhatsAppModal
