import React from 'react'
// import Paragraph from 'antd/lib/typography/Paragraph'
// import 'antd/lib/typography/style/index.css' //added specific style

import { CenterContainer } from './styles'
import WhatsAppLogo from '../WhatsAppLogo'
import {ContainerCenter, ContainerParagraph} from "./styles";
import {ContainerTitle} from "../ApplicationAlreadySuccessful/styles";


function ApplicationAlreadySuccessful() {
    return (
      <ContainerCenter>
        <React.Fragment>
          <WhatsAppLogo/>
        </React.Fragment>
        <React.Fragment>
          <ContainerTitle id="modal-title-already-applied">{window.whatsAppOptInConfig.alreadyAppliedText.title}</ContainerTitle>
        </React.Fragment>
        <ContainerParagraph id="modal-already-success-message">{window.whatsAppOptInConfig.alreadyAppliedText.paragraph}</ContainerParagraph>
      </ContainerCenter>
    )
}

export default ApplicationAlreadySuccessful
