import React from 'react'
// import Paragraph from 'antd/lib/typography/Paragraph'
// import 'antd/lib/typography/style/index.css' //added specific style

import { CenterContainer } from '../ApplicationAlreadySuccessful/styles'
import WhatsAppLogo from '../WhatsAppLogo'
import {ContainerCenter} from "../ApplicationSuccessful/styles";
import {ContainerTitle, ContainerParagraph} from "../ApplicationSubmitionError/styles";

function ApplicationSubmissionError() {
    return (
      <ContainerCenter>
        <React.Fragment>
          <WhatsAppLogo/>
        </React.Fragment>
        <React.Fragment>
          <ContainerTitle id="modal-title-already-applied">{window.whatsAppOptInConfig.errorText.title}</ContainerTitle>
        </React.Fragment>
        <ContainerParagraph id="modal-submission-error-message">{window.whatsAppOptInConfig.errorText.paragraph}</ContainerParagraph>
      </ContainerCenter>
    )
}

export default ApplicationSubmissionError
