import React from 'react'
// import Paragraph from 'antd/lib/typography/Paragraph'
// import 'antd/lib/typography/style/index.css' //added specific style

import {ContainerCenter, ContainerParagraph} from './styles'
import WhatsAppLogo from '../WhatsAppLogo'
import {ContainerTitle} from "../ApplicationSuccessful/styles";


function ApplicationSuccessful() {
    return (
        <ContainerCenter>
            <React.Fragment>
              <WhatsAppLogo/>
            </React.Fragment>
            <React.Fragment>
              <ContainerTitle id="modal-title-success">{window.whatsAppOptInConfig.successText.title}</ContainerTitle>
            </React.Fragment>
            <ContainerParagraph id="modal-success-message">{window.whatsAppOptInConfig.successText.paragraph}</ContainerParagraph>
        </ContainerCenter>


    )
}

export default ApplicationSuccessful
