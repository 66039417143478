import React, {useState, useEffect} from 'react';
import {
  ContainerRow,
  ContainerTitle,
  ContainerMain,
  ButtonConfirm,
  ContainerCenter,
  ContainerParagraph,
  Agreement, AgreementLink
} from './styles';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import Loader from 'react-loader-spinner'
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import ApplicationSuccessful from '../ApplicationSuccessful';
import WhatsAppLogo from '../WhatsAppLogo'
import ApplicationAlreadySuccessful from '../ApplicationAlreadySuccessful';
import ApplicationSubmissionError from '../ApplicationSubmitionError';

function WhatsAppForm({visible}) {
  const JOBOTI_ALREADY_SUBMITTED = 'jobotiIsAlreadySubmitted'
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState(window.whatsAppOptInConfig.defaultCountryPhoneNumberCode);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(localStorage.getItem(JOBOTI_ALREADY_SUBMITTED) === 'true');
  const [hasError, setHasError] = useState(false);


  useEffect(() => {
    // Reset everything after modal closed
    setTimeout(() => {
      setPhoneNumber('');
      setCountryCode(window.whatsAppOptInConfig.defaultCountryPhoneNumberCode);
      setLoading(false);
      setIsSubmitted(false);
      setIsCheckboxChecked(false);
    }, 150);
  }, [visible]);

  function isFormValid() {
    return isValidPhoneNumber() && isCheckboxChecked;
  }

  function postData() {
    const url = process.env.FAAS_URL;
    const data = {
      chatbotId: window.waConfig.chatbotId,
      whatsAppTemplateName: window.waConfig.whatsAppTemplateName,
      whatsAppTemplateParams: window.waConfig.whatsAppTemplateParams,
      whatsAppTemplateAdditionalData: window.waConfig.whatsAppTemplateAdditionalData,
      whatsAppTemplateButtons: window.waConfig.whatsAppTemplateButtons,
      flowId: window.waConfig.flowId,
      phoneNumber,
      sourceUrl: window.location.href
    }

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      mode: 'cors',
    })
  }

  function handleSubmit() {
    setLoading(true);
    postData().then((res) => {
      if(res.status === 409){
        setIsAlreadySubmitted(true);
      }
      setIsSubmitted(true);
      setLoading(false);

    }).catch(() => {
      setHasError(true);
      setLoading(false);
    });
  }

  function handlePhoneNumberChange(value, data) {
    setCountryCode(data.countryCode);
    setPhoneNumber(value);
  }

  function isValidPhoneNumber() {
    const num = parsePhoneNumberFromString(phoneNumber, countryCode.toUpperCase());
    if (num) {
      return num.isValid();
    }
    return false;
  }

  if (!loading && hasError) {
    return <ApplicationSubmissionError/>;
  }

  if (!loading && isAlreadySubmitted) {
    return <ApplicationAlreadySuccessful/>;
  }

  if (!loading && isSubmitted) {
    return <ApplicationSuccessful/>;
  }

  if (loading) {
    return (
      <ContainerCenter>
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={36}
          width={36}
          timeout={0} //3 secs
        />
      </ContainerCenter>
    );
  }

  return (
    <ContainerMain>
      <ContainerRow style={{clear:'both'}}>
        <React.Fragment>
          <WhatsAppLogo />
        </React.Fragment>
      </ContainerRow>
      <ContainerRow>
        <React.Fragment>
          <ContainerTitle id="modal-title">{window.whatsAppOptInConfig.defaultText.title}</ContainerTitle>
        </React.Fragment>
      </ContainerRow>
      <ContainerRow>
        <React.Fragment>
          <ContainerParagraph id="modal-description">{window.whatsAppOptInConfig.defaultText.description}</ContainerParagraph>
        </React.Fragment>
      </ContainerRow>
      <ContainerRow>
        <React.Fragment>
          <PhoneInput
            inputExtraProps={{
              name: 'phone',
              required: true,
              autoFocus: true,
            }}
            country={countryCode}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            isValid={() => !phoneNumber || isValidPhoneNumber()}
          />
        </React.Fragment>
      </ContainerRow>
      <ContainerRow style={{marginBottom: '0px'}}>
        <React.Fragment>
          <input
            type="checkbox"
            onChange={({target: {checked}}) => setIsCheckboxChecked(checked)}
            style={{marginRight:'8px', cursor:'pointer'}}
            checked={isCheckboxChecked}/>
          <Agreement onClick={() => setIsCheckboxChecked(prevChecked => !prevChecked)} id="modal-terms-service-text">{window.whatsAppOptInConfig.defaultText.checkboxMessage}</Agreement>
        </React.Fragment>
      </ContainerRow>
      <ContainerRow>
        {
          window.whatsAppOptInConfig.defaultText.checkboxUrlText.length > 0 &&
          window.whatsAppOptInConfig.defaultText.checkboxUrlHref.length > 0 &&
          (
            <a href={window.whatsAppOptInConfig.defaultText.checkboxUrlHref} target="_blank" id="modal-link-href">
              <AgreementLink id="modal-link-text">{window.whatsAppOptInConfig.defaultText.checkboxUrlText}</AgreementLink>
            </a>
          )
        }
      </ContainerRow>
      <ContainerRow style={{marginTop:'24px'}}>
        <React.Fragment>
          { isFormValid() ?
            <ButtonConfirm id="modal-apply-btn" onClick={handleSubmit}>
              {window.whatsAppOptInConfig.defaultText.buttonMessage}
            </ButtonConfirm>
            :
            <ButtonConfirm id="modal-apply-btn"
                           onClick={handleSubmit}
                           style={{cursor:'default',border:'1px solid #CACACA', opacity:'0.3'}}
                           disabled>
              {window.whatsAppOptInConfig.defaultText.buttonMessage}
            </ButtonConfirm>
          }
        </React.Fragment>
      </ContainerRow>
    </ContainerMain>
  );
}

export default WhatsAppForm;
