import React from 'react'
import ReactJson from 'react-json-view'
// import Text from 'antd/lib/typography/Text'
// import 'antd/lib/typography/style/index.css' //added specific style

function ConfigError({ validationErrors }) {
    return (
        <React.Fragment>
            <h2 style={{ color: 'red' }}>Invalid  <p>window.waConfig</p> </h2>
            <ReactJson src={validationErrors.map((err) => err.message)} enableClipboard={false} />
        </React.Fragment>
    )
}

export default ConfigError
