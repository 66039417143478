import React, { useState } from 'react';
import Modal from 'react-modal'
import WhatsAppModal from './components/WhatsAppModal';

function App() {
  const [visible, setVisible] = useState(window.whatsAppOptInConfig.autoOpen);
  function showModal() {
    setVisible(true);
  }

  function hideModal() {
    setVisible(false);
  }

  return (
    <div>
      <WhatsAppModal visible={visible} showModal={showModal} hideModal={hideModal} />
      <div id="open_whatsapp_opt_in" onClick={e => {showModal();}}></div>
    </div>
  );
}

export default App;

